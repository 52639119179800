import React from 'react';
import AppContext from './AppContext';
import "../scss/Main.scss";
import ModelRA from "./ModelRA";
import ModelRAVolume from "./ModelRAVolume";
import ModelROM from "./ModelROM";

class Main extends React.Component {

  static contextType = AppContext;

  // constructor(props) {
  //   super(props);
  // }

  state = {
    modelOverlay: true
  };
 
    render() {

      const {currentSlide} = this.context;
      
      return (
        <div className="model">
          
            <div className={"model-overlay " + (currentSlide === 0 && this.state.modelOverlay === true ? 'overlay-current' : '')} onClick={(modelOverlay) => this.setState({ modelOverlay })}>
            
              <div>
                <img src="/images/icon_rotation.svg" alt="" />
                <p>Click and drag to explore the model.</p>
              </div>
            </div> 
            
            <div className={"slide-content " + (currentSlide === 0 || currentSlide === 1 || currentSlide === 2 ? 'slide-current' : '')}>
              <h2>TGCT/PVNS<br></br>Clinical Presentation</h2>
              <div className="model-bottom">
                <p>Click and drag to explore the model.</p>
              </div>
            </div>
            <div className={"slide-content " + (currentSlide === 3 ? 'slide-current' : '')}>
              <h2>Response Assessment<br></br>by RECIST v1.1 in ENLIVEN</h2>
              <div className="model-left">
                <div className="red-line"></div>
                <p>Length</p>
              </div>
              <div className="model-bottom">
                <p>Slide to view tumor reduction by RECIST v1.1</p>
              </div>
            </div>
            <div className={"slide-content " + (currentSlide === 4 ? 'slide-current' : '')}>
              <h2>Response Assessment by Tumor<br></br>Volume Score (TVS) in ENLIVEN</h2>
              <div className="model-left">
                <img src="/images/icon_multidimensional_tumor_assessment.svg" alt="" />
                <p>Volume</p>
              </div>
              <div className="model-bottom">
                <p>Slide to view tumor reduction by tumor volume score (TVS)</p>
              </div>
            </div>
            <div className={"slide-content " + (currentSlide === 5 ? 'slide-current' : '')}>
              <h2>Range of Motion (ROM)<br></br>and Patient Mobility in ENLIVEN</h2>
              <div className="model-bottom">
                <p>Slide to view how change in ROM from baseline corresponds to knee flexion</p>
              </div>
            </div>

            <div className={"model-content modelRA " + ((currentSlide !== 5 && currentSlide !== 4) ? 'model-current' : '')}>
              <ModelRA />
            </div>
          <div className={"model-content modelRAVolume " + (currentSlide === 4 ? 'model-current' : '')}>
            <ModelRAVolume />
          </div>
            <div className={"model-content modelROM " + (currentSlide === 5 ? 'model-current' : '')}>
              <ModelROM />
            </div>
             
            <p>The Tumor Reduction model is a representation only and measurements are not exact. It is intended to be a model only. Individual results may vary.</p>
          </div>
      
      );
    }
  }
  
  export default Main;
  