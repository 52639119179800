import React from 'react';
import ReactModal from 'react-modal';
import "../scss/Modal.scss";

class ModalROMData extends React.Component {
  
  constructor(props) {
    super(props);
      
    this.state = {
      showModalROMData: false
    };
    this.handleOpenModalROMData = this.handleOpenModalROMData.bind(this);
    this.handleCloseModalROMData = this.handleCloseModalROMData.bind(this);

  }
   
  handleOpenModalROMData () {
    this.setState({ showModalROMData: true });
  }
  
  handleCloseModalROMData () {
    this.setState({ showModalROMData: false });
  }

    render() {
      
      return (
        <div>
          <button className="button button-data button-rom-data" onClick={this.handleOpenModalROMData}>See how ROM impacts typical daily activity</button>
          <ReactModal 
            isOpen={this.state.showModalROMData}
            contentLabel="onRequestClose"
            onRequestClose={this.handleCloseModalROMData}
            className="Modal modal_rom_data"
            overlayClassName="Overlay"
            appElement={document.querySelector('.study')}
          >
            <button onClick={this.handleCloseModalROMData}></button>
            <div>
              <img src="/images/rom_data.png" alt="" />
              <p><sup>b</sup>Mean patient age was 67 years.</p>
            </div>
          </ReactModal>      
        </div>
      );
    }
  }
  
  export default ModalROMData;
  