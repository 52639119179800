import React from 'react';
import AppContext from './AppContext';
import { Range, getTrackBackground } from "react-range";
import { Clock } from 'three';
import { Scene } from 'three';
import { TOUCH } from 'three';
import { PerspectiveCamera } from 'three';
import { AmbientLight } from 'three';
import { PointLight } from 'three';
import { WebGLRenderer } from 'three';
import { ACESFilmicToneMapping } from 'three';
import { sRGBEncoding } from 'three';
import { Box3 } from 'three';
import { Vector3 } from 'three';
import { AnimationMixer } from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import "../scss/ModelRA.scss";
class ModelRA extends React.Component {

    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            rangeLaValue: [0],
            rangeMtaValue: [0],
            rangeLa30: [0],
            rangeLa100: [0],
            rangeMta50: [0],
            rangeMta100: [0],
            currentPosY: -0.01,
            currentScaleY: 1.0,
            percentBox: false
        };
        this.scaleTumorRA0 = this.scaleTumorRA0.bind(this);
        this.scaleTumorRA30 = this.scaleTumorRA30.bind(this);
        this.scaleTumorRA50 = this.scaleTumorRA50.bind(this);
        this.scaleTumorRA100 = this.scaleTumorRA100.bind(this);
        this.changeModel = this.changeModel.bind(this);
    }



    componentDidMount = () => {

        let scene, camera, renderer;
        let modelElSizeX = 1040,
            modelEleSizeY = 676;

        let clock,
            modelRA,
            mixer = null;

        function init() {
            var controls, hlight, light; //directionalLight,
            clock = new Clock();
            scene = new Scene();

            camera = new PerspectiveCamera(
                35,
                modelElSizeX / modelEleSizeY,
                0.05,
                300
            );
            camera.position.z = -2

            hlight = new AmbientLight(0x404040, 100);
            scene.add(hlight);

            light = new PointLight(0xc4c4c4, 10);
            light.position.set(0, 300, 500);
            scene.add(light);

            renderer = new WebGLRenderer({ antialias: true, alpha: true });
            renderer.setSize(modelElSizeX, modelEleSizeY);
            renderer.setPixelRatio(window.devicePixelRatio);
            renderer.toneMapping = ACESFilmicToneMapping;
            renderer.toneMappingExposure = 1;
            renderer.outputEncoding = sRGBEncoding;
            renderer.setClearColor(0xffffff, 0);

            document.getElementsByClassName('modelRA')[0].appendChild(renderer.domElement);

            controls = new OrbitControls(camera, renderer.domElement);

            controls.touches = {
                ONE: TOUCH.ROTATE,
                TWO: TOUCH.DOLLY_PAN
            }

            controls.minDistance = 1.25;
            controls.maxDistance = 5;
            controls.target.set(0, 0, -0.2);
            controls.update();

            var loader = new GLTFLoader();
            loader.load(
                "model/Tumour_WithMeasurements/Tumour_WithMeasurements.gltf",
                function (gltf) {
                    modelRA = gltf.scene;
                    if (!window.theModelRA) window.theModelRA = modelRA;

                    var box = new Box3().setFromObject(modelRA);
                    var center = new Vector3();
                    box.getCenter(center);
                    modelRA.position.sub(center); // center the model
                    scene.rotation.y = -100 * Math.PI / 180

                    scene.add(modelRA);

                    console.log(modelRA);
                    if (gltf.animations[0]) {
                        mixer = new AnimationMixer(modelRA);
                    }

                    animate();

                }, // called while loading is progressing
                function (xhr) {
                    console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
                },
                // called when loading has errors
                function (error) {
                    console.log("An error happened", error);
                }
            );
        }

        function animate() {
            requestAnimationFrame(animate);

            var delta = clock.getDelta();
            if (mixer !== null) {
                mixer.update(delta);
            }

            // renderer.gammaOutput = false; // to fix washed-out model
            renderer.toneMappingExposure = 0.1;  // to fix washed-out model
            renderer.render(scene, camera);
        }

        init();

    }

    scaleTumorRA0 = () => {
        // .find(x => x.name === 'Rotation_Point001').children.find(y => y.name === '100%Tumour_Morpher')
        // eslint-disable-next-line
        this.state.currentPosY = -0.01;
        // eslint-disable-next-line
        this.state.currentScaleY = 1.0;
        window.theModelRA.children.find(x => x.name === 'Rotation_Point001').children.find(y => y.name === '100%Tumour_Morpher').visible = true; // add this back if it was removed for 100%
        window.theModelRA.children.find(x => x.name === 'Rotation_Point001').children.find(y => y.name === '100%Tumour_Morpher').position.setY(this.state.currentPosY);
        window.theModelRA.children.find(x => x.name === 'Rotation_Point001').children.find(y => y.name === '100%Tumour_Morpher').scale.setY(this.state.currentScaleY);

    }

    scaleTumorRA30 = () => {

        let count = 0;
        // eslint-disable-next-line
        this.state.currentPosY = -0.01;
        // eslint-disable-next-line
        this.state.currentScaleY = 1.0;
        let currentPosY = this.state.currentPosY,
            //endPosY = -0.05,
            currentScaleY = this.state.currentScaleY;
        //endScaleY = 0.6;

        let timeInt = window.setInterval(function () {

            window.theModelRA.children.find(x => x.name === 'Rotation_Point001').children.find(y => y.name === '100%Tumour_Morpher').position.setY(currentPosY);
            window.theModelRA.children.find(x => x.name === 'Rotation_Point001').children.find(y => y.name === '100%Tumour_Morpher').scale.setY(currentScaleY);
            window.theModelRA.children.find(x => x.name === 'Rotation_Point001').children.find(y => y.name === '100%Tumour_Morpher').visible = true; // add this back if it was removed for 100%
            //console.log( 'positionY: ',count, window.theModelRA.children[2].children[2].position.y);
            //console.log( 'scaleY: ',count, currentScaleY);
            currentPosY = currentPosY - 0.01;
            currentScaleY = currentScaleY - 0.1;
            count++;
            if (count >= 5) {
                clearInterval(timeInt);
            }
        }, 500);

    }

    scaleTumorRA50 = () => {

        let count = 0;
        // eslint-disable-next-line
        this.state.currentPosY = -0.01;
        // eslint-disable-next-line
        this.state.currentScaleY = 1.0;
        let currentPosY = this.state.currentPosY,
            //endPosY = -0.05,
            currentScaleY = this.state.currentScaleY;
        //endScaleY = 0.6;
        // window.theModelRA.children[2].children[2].visible = true;
        let timeInt = window.setInterval(function () {
            window.theModelRA.children.find(x => x.name === 'Rotation_Point001').children.find(y => y.name === '100%Tumour_Morpher').position.setY(currentPosY);
            window.theModelRA.children.find(x => x.name === 'Rotation_Point001').children.find(y => y.name === '100%Tumour_Morpher').scale.setY(currentScaleY);
            //console.log( 'positionY: ',count, window.theModelRA.children[2].children[2].position.y);
            //console.log( 'scaleY: ',count, currentScaleY);
            currentPosY = currentPosY - 0.01;
            currentScaleY = currentScaleY - 0.1;
            count++;
            if (count >= 7) {
                clearInterval(timeInt);
            }
        }, 500);

    }

    scaleTumorRA100 = () => {

        let count = 0;
        // eslint-disable-next-line
        this.state.currentPosY = -0.01;
        // eslint-disable-next-line
        this.state.currentScaleY = 1.0;
        let currentPosY = this.state.currentPosY,
            currentScaleY = this.state.currentScaleY;

        let timeInt = window.setInterval(function () {
            window.theModelRA.children.find(x => x.name === 'Rotation_Point001').children.find(y => y.name === '100%Tumour_Morpher').position.setY(currentPosY);
            window.theModelRA.children.find(x => x.name === 'Rotation_Point001').children.find(y => y.name === '100%Tumour_Morpher').scale.setY(currentScaleY);
            //console.log( 'positionY: ',count, window.theModelRA.children[2].children[2].position.y);
            //console.log( 'scaleY: ',count, currentScaleY);
            currentPosY = currentPosY - 0.01;
            currentScaleY = currentScaleY - 0.1;
            count++;
            if (count >= 10) {
                window.theModelRA.children.find(x => x.name === 'Rotation_Point001').children.find(y => y.name === '100%Tumour_Morpher').position.setY(1);
                window.theModelRA.children.find(x => x.name === 'Rotation_Point001').children.find(y => y.name === '100%Tumour_Morpher').visible = false; // remove this so it doesn't show as an artifact -> put it back for the other animations
                clearInterval(timeInt);
            }
        }, 500);

    }

    changeModel = (rangeLaValue) => {
        //comment to trigger dev build
        // eslint-disable-next-line
        if (rangeLaValue[0] == 0) {
            this.scaleTumorRA0();
            // eslint-disable-next-line
        } else if (rangeLaValue[0] == 30) {
            this.scaleTumorRA30();
            // eslint-disable-next-line
        }
        //   else if (rangeLaValue[0] == 50) {
        //     this.scaleTumorRA50();
        // }
        // eslint-disable-next-line
         else if (rangeLaValue[0] == 100) {
            this.scaleTumorRA100();
        }
        // SNAPS TO 0, 30, or 100 depending on what's closest
        if(rangeLaValue[0] === 10) {
            this.setState({
                rangeLaValue: [0]
            });
            this.scaleTumorRA0();
        }
        if(rangeLaValue[0] === 20) {
            this.setState({
                rangeLaValue: [30]
            });
            this.scaleTumorRA30();
        }
        if(rangeLaValue[0] === 40) {
            this.setState({
                rangeLaValue: [30]
            });
            this.scaleTumorRA30();
        }
        if(rangeLaValue[0] === 50) {
            this.setState({
                rangeLaValue: [30]
            });
            this.scaleTumorRA30();
        }
        if(rangeLaValue[0] === 60) {
            this.setState({
                rangeLaValue: [30]
            });
            this.scaleTumorRA30();
        }
        if(rangeLaValue[0] === 70) {
            this.setState({
                rangeLaValue: [100]
            });
            this.scaleTumorRA100();
        }
        if(rangeLaValue[0] === 80) {
            this.setState({
                rangeLaValue: [100]
            });
            this.scaleTumorRA100();
        }
        if(rangeLaValue[0] === 90) {
            this.setState({
                rangeLaValue: [100]
            });
            this.scaleTumorRA100();
        }
    }



    render() {

        let step = 10,
            min = 0,
            max = 100,
            ocean = '#637687',
            // ocean = '#A5C8E4',
            sky = '#A5C8E4',
        //    white = '#FFFFFF',
            deepSky = '#0086B6',
            gray = '#D2E4F2';


        function togglemeasurments(status) {
            let start = 5,
                end = 15;
            while (start < end) {
                window.theModelRA.children[start].visible = status;
                start++;
            }
        }

        window.setTimeout(function () {
            togglemeasurments(false);
        }, 5000)


        const { currentSlide } = this.context;
        // let btn_class = this.state.black ? "blackButton" : "whiteButton";
        return (

            <div ref={el => (this.container = el)}>

                {/* eslint-disable */}
                <div className={"range-slider range-slider-la " + (currentSlide == 3 ? 'range-current' : '')}>
                    <h3>Percent Tumor<br></br>Reduction</h3>
                    <div>

                        <Range
                            step={step}
                            min={min}
                            max={max}

                            values={this.state.rangeLaValue}
                            onChange={(rangeLaValue) => this.setState({ rangeLaValue })}
                            onFinalChange={(rangeLaValue) => this.changeModel(rangeLaValue)}
                            renderMark={({ props, index }) => (
                                <div
                                    {...props}
                                    className="slider-point"
                                    style={{
                                        backgroundColor: index * step < this.state.rangeLaValue[0] ? '#0086B6' : '#A5C8E4'
                                    }}
                                >
                                    <div className="point-label">
                                        {index == 0 ? '0%' : ''}
                                        {index == 3 ? <>
                                            30%
                                            <p>
                                                (partial response)
                                            </p>

                                        </> : ''}
                                        {index == 10 ?
                                          <>
                                              100%
                                              <p>
                                                  (complete response)
                                              </p>

                                          </> : ''}
                                    </div>

                                </div>

                            )}
                            renderTrack={({ props, children }) => (
                                <div
                                    {...props}
                                    className="slider-bar"
                                    style={{
                                        background: getTrackBackground({
                                            values: this.state.rangeLaValue,
                                            colors: [sky, ocean],
                                            min: min,
                                            max: max
                                        })
                                    }}
                                >
                                    {children}
                                </div>
                            )}
                            renderThumb={({ props }) => (
                                <div
                                    {...props}
                                    className="slider-pointer"
                                />
                            )}
                        />
                    </div>
                </div>

                {
                    currentSlide == 3 ?

                        <div className={"range-box rangeLa30 " + (this.state.rangeLaValue == 30 ? 'range-box-current' : '')}>

                            <h2>
                                {(this.state.rangeLa30 == 0 ? '38%' : '')}
                                {(this.state.rangeLa30 == 100 ? '62%' : '')}
                                <span> ORR</span>
                            </h2>

                            <div>
                                <h3>
                                    {(this.state.rangeLa30 == 0 ? '23%' : '')}
                                    {(this.state.rangeLa30 == 100 ? '33%' : '')}
                                </h3>
                                <p>of patients on TURALIO had a PR<sup>
                                    {(this.state.rangeLa30 == 0 ? '1' : '')}
                                    {(this.state.rangeLa30 == 100 ? '7' : '')}</sup>
                                    </p>
                            </div>
                            <Range
                                step={100}
                                min={min}
                                max={max}
                                values={this.state.rangeLa30}
                                onChange={(rangeLa30) => this.setState({ rangeLa30 })}
                                renderMark={({ props, index }) => (
                                    <div
                                        {...props}
                                        className="slider-point"
                                    >
                                        <div className={`point-label ${this.state.rangeLa30 == 0 ? 'active' :'inactive'}`}>
                                            {index == 0 ? '25 weeks' : ''}
                                        </div>
                                        <div className={`point-label ${this.state.rangeLa30 == 100 ? 'active' :'inactive'}`}>
                                            {index == 1 ? '38 months' : ''}
                                        </div>

                                    </div>

                                )}
                                renderTrack={({ props, children }) => (
                                    <div
                                        {...props}
                                        className="slider-bar"
                                        style={{
                                            background: getTrackBackground({
                                                values: this.state.rangeLa30,
                                                colors: [deepSky, gray],
                                                min: min,
                                                max: max
                                            })
                                        }}
                                    >
                                        {children}
                                    </div>
                                )}
                                renderThumb={({ props }) => (
                                    <div
                                        {...props}
                                        className="slider-pointer"
                                    />
                                )}
                            />
                        </div>

                        : null
                }

                {
                    currentSlide == 3 ?

                        <div className={"range-box rangeLa100 " + (this.state.rangeLaValue == 100 ? 'range-box-current' : '')}>
                            <h2>
                                {(this.state.rangeLa100 == 0 ? '38%' : '')}
                                {(this.state.rangeLa100 == 100 ? '62%' : '')}
                                <span> ORR</span>
                            </h2>
                            <div>
                                <h3>
                                    {(this.state.rangeLa100 == 0 ? '15%' : '')}
                                    {(this.state.rangeLa100 == 100 ? '30%' : '')}
                                </h3>
                                <p>of patients on TURALIO had a CR<sup>
                                    {(this.state.rangeLa100 == 0 ? '1' : '')}
                                    {(this.state.rangeLa100 == 100 ? '7' : '')}</sup>
                                </p>
                            </div>
                            <Range
                                step={100}
                                min={min}
                                max={max}
                                values={this.state.rangeLa100}
                                onChange={(rangeLa100) => this.setState({ rangeLa100 })}
                                renderMark={({ props, index }) => (
                                    <div
                                        {...props}
                                        className="slider-point"
                                    >
                                        <div className={`point-label ${this.state.rangeLa100 == 0 ? 'active' :'inactive'}`}>
                                            {index == 0 ? '25 weeks' : ''}
                                        </div>
                                        <div className={`point-label ${this.state.rangeLa100 == 100 ? 'active' :'inactive'}`}>
                                            {index == 1 ? '38 months' : ''}
                                        </div>

                                    </div>

                                )}
                                renderTrack={({ props, children }) => (
                                    <div
                                        {...props}
                                        className="slider-bar"
                                        style={{
                                            background: getTrackBackground({
                                                values: this.state.rangeLa100,
                                                colors: [deepSky, gray],
                                                min: min,
                                                max: max
                                            })
                                        }}
                                    >
                                        {children}
                                    </div>
                                )}
                                renderThumb={({ props }) => (
                                    <div
                                        {...props}
                                        className="slider-pointer"
                                    />
                                )}
                            />
                        </div>

                        : null
                }


                {
                    currentSlide === 3 && this.state.rangeLa100 == 100?

                        <div className={"percent-box " + (this.state.rangeLaValue == 100 ? 'percent-box-current' : '')}>

                            <div>

                                <div>
                                    <p>Serious adverse reactions<br></br>occurred in<sup>1</sup>:</p>
                                </div>
                                <div>
                                    <div className="c100 p13">
                                        <span>
                                            <span>13%</span>
                                            of patients<br></br>(n=8/61)
                                        </span>
                                        <div className="slice">
                                            <div className="bar"></div>
                                            <div className="fill"></div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <button className="btn-plus" onClick={() => {

                                        this.setState({ percentBox: !this.state.percentBox })
                                        let holder = document.getElementsByClassName('ReactModalPortal');
                                        holder[0].setAttribute('style','position:fixed;top:0;left:0;right:0;bottom:0;background-color: #58647583;')

                                    }}>+</button>
                                    {
                                        this.state.percentBox ?
                                            <div className="percent-box-copy">
                                                <button onClick={() => {
                                                    this.setState({ percentBox: !this.state.percentBox })
                                                    let holder = document.getElementsByClassName('ReactModalPortal');
                                                    holder[0].setAttribute('style','')

                                                }}></button>
                                                <div>
                                                    <p className="mb10"><strong>Most frequent (&gt;1 patient):</strong></p>
                                                    <div className="flex">
                                                        <div>
                                                            <p><b>Abnormal Liver Test</b></p>
                                                            <ul>
                                                                <li>3.3% (2) patients</li>
                                                            </ul>
                                                        </div>
                                                        <div>
                                                            <p><b>Hepatotoxicity</b><sup>a</sup></p>
                                                            <ul>
                                                                <li>3.3% (2) patients</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <p className="reference"><sup>a</sup>The same 2 patients with hepatotoxicity who reported serious adverse<br></br>reactions also discontinued the study.<sup>1,7</sup></p>
                                                </div>
                                            </div>
                                            : null
                                    }
                                </div>

                            </div>

                        </div>


                        : null
                }

                <div className={"range-slider range-slider-mta " + (currentSlide == 4 ? 'range-current' : '')}>
                    <h3>Percent Tumor<br></br>Reduction</h3>
                    <div>
                        <Range
                            step={step}
                            min={min}
                            max={max}
                            values={this.state.rangeMtaValue}
                            onChange={(rangeMtaValue) => this.setState({ rangeMtaValue })}
                            onFinalChange={(rangeMtaValue) => this.changeModel(rangeMtaValue)}
                            renderMark={({ props, index }) => {
                                //console.log('params',this.state.rangeMtaValue)
                                return(
                                    <div
                                      {...props}
                                      className="slider-point"
                                      style={{
                                        backgroundColor: index * step < this.state.rangeMtaValue[0] ? '#0086B6' : '#A5C8E4'
                                    }}
                                    >
                                        <div className="point-label">
                                            {index == 0 ? '0%' : ''}
                                            {index == 5 ?
                                              <>
                                                  50%
                                                  <p>
                                                      (partial response)
                                                  </p>

                                              </>
                                              : ''}
                                            {index == 10 ?
                                              <>
                                                  100%
                                                  <p>
                                                      (complete response)
                                                  </p>

                                              </>
                                              : ''}
                                        </div>

                                    </div>

                                  )

                            }}
                            renderTrack={({ props, children }) => (
                                <div
                                    {...props}
                                    className="slider-bar"
                                    style={{
                                        background: getTrackBackground({
                                            values: this.state.rangeMtaValue,
                                            colors: [sky, ocean],
                                            min: min,
                                            max: max
                                        })
                                    }}
                                >
                                    {children}
                                </div>
                            )}
                            renderThumb={({ props }) => (
                                <div
                                    {...props}
                                    className="slider-pointer"
                                />
                            )}
                        />
                    </div>
                </div>


                {
                    currentSlide == 4 ?

                        <div className={"range-box rangeMta50 " + (this.state.rangeMtaValue == 50 ? 'range-box-current' : '')}>

                            <h2>
                                {(this.state.rangeMta50 == 0 ? '56%' : '')}
                                {(this.state.rangeMta50 == 100 ? '66%' : '')}
                                <span> ORR</span>
                            </h2>

                            <div>
                                <h3>
                                    {(this.state.rangeMta50 == 0 ? '51%' : '')}
                                    {(this.state.rangeMta50 == 100 ? '57%' : '')}
                                </h3>
                                <p>of patients on TURALIO had a PR</p>
                            </div>
                            <Range
                                step={100}
                                min={min}
                                max={max}
                                values={this.state.rangeMta50}
                                onChange={(rangeMta50) => this.setState({ rangeMta50 })}
                                //onFinalChange={(rangeMta50) => this.changeModel(rangeMta50)}
                                renderMark={({ props, index }) => (
                                    <div
                                        {...props}
                                        className="slider-point"
                                    >
                                        <div className={`point-label ${this.state.rangeMta50 == 0 ? 'active' :'inactive'}`}>
                                            {index == 0 ? '25 weeks' : ''}
                                        </div>
                                        <div className={`point-label ${this.state.rangeMta50 == 100 ? 'active' :'inactive'}`}>
                                            {index == 1 ? '38 months' : ''}
                                        </div>

                                    </div>

                                )}
                                renderTrack={({ props, children }) => (
                                    <div
                                        {...props}
                                        className="slider-bar"
                                        style={{
                                            background: getTrackBackground({
                                                values: this.state.rangeMta50,
                                                colors: [deepSky, gray],
                                                min: min,
                                                max: max
                                            })
                                        }}
                                    >
                                        {children}
                                    </div>
                                )}
                                renderThumb={({ props }) => (
                                    <div
                                        {...props}
                                        className="slider-pointer"
                                    />
                                )}
                            />
                        </div>

                        : null
                }

                {
                    currentSlide == 4 ?

                        <div className={"range-box rangeMta100 " + (this.state.rangeMtaValue == 100 ? 'range-box-current' : '')}>
                            <h2>
                                {(this.state.rangeMta100 == 0 ? '56%' : '')}
                                {(this.state.rangeMta100 == 100 ? '66%' : '')}
                                <span> ORR</span>
                            </h2>
                            <div>
                                <h3>
                                    {(this.state.rangeMta100 == 0 ? '5%' : '')}
                                    {(this.state.rangeMta100 == 100 ? '8%' : '')}
                                </h3>
                                <p>of patients on TURALIO had a CR</p>
                            </div>
                            <Range
                                step={100}
                                min={min}
                                max={max}
                                values={this.state.rangeMta100}
                                onChange={(rangeMta100) => this.setState({ rangeMta100 })}
                                //onFinalChange={(rangeMta100) => this.changeModel(rangeMta100)}
                                renderMark={({ props, index }) => (
                                    <div
                                        {...props}
                                        className="slider-point"
                                    >
                                        <div className={`point-label ${this.state.rangeMta100 == 0 ? 'active' :'inactive'}`}>
                                            {index == 0 ? '25 weeks' : ''}
                                        </div>
                                        <div className={`point-label ${this.state.rangeMta100 == 100 ? 'active' :'inactive'}`}>
                                            {index == 1 ? '38 months' : ''}
                                        </div>
                                    </div>

                                )}
                                renderTrack={({ props, children }) => (
                                    <div
                                        {...props}
                                        className="slider-bar"
                                        style={{
                                            background: getTrackBackground({
                                                values: this.state.rangeMta100,
                                                colors: [deepSky, gray],
                                                min: min,
                                                max: max
                                            })
                                        }}


                                    >
                                        {children}
                                    </div>
                                )}
                                renderThumb={({ props }) => (
                                    <div
                                        {...props}
                                        className="slider-pointer"
                                    />
                                )}
                            />
                        </div>


                        : null
                }

                {
                    currentSlide == 4 && this.state.rangeMta100 == 0 ?

                        <div className={"percent-box " + (this.state.rangeMtaValue == 100 ? 'percent-box-current' : '')}>

                            <div>
                                <div>
                                    <p>The most frequent (&gt;1 patient)<br></br>permanent discontinuation due to<br></br>an adverse reaction occurred in:</p>
                                </div>
                                <div>
                                    <div className="c100 p13">
                                        <span>
                                            <span>13%</span>
                                            of patients<br></br>(n=8/61)
                                        </span>
                                        <div className="slice">
                                            <div className="bar"></div>
                                            <div className="fill"></div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <button className="btn-plus" onClick={() => {
                                        let holder = document.getElementsByClassName('ReactModalPortal');
                                        holder[0].setAttribute('style','position:fixed;top:0;left:0;right:0;bottom:0;background-color: #58647583;')

                                        this.setState({ percentBox: !this.state.percentBox })
                                    }}>+</button>
                                    {
                                        this.state.percentBox ?
                                            <div className="percent-box-copy">
                                                <button onClick={() => {
                                                    this.setState({ percentBox: !this.state.percentBox })
                                                    let holder = document.getElementsByClassName('ReactModalPortal');
                                                    holder[0].setAttribute('style','')

                                                }}></button>
                                                <div>
                                                    <p className="mb10"><strong>Most frequent (&gt;1 patient):</strong></p>
                                                    <div className="flex">
                                                        <div>
                                                            <p><b>Abnormal Liver Test</b></p>
                                                            <ul>
                                                                <li>3.3% (2) patients</li>
                                                            </ul>
                                                        </div>
                                                        <div>
                                                            <p><b>Hepatotoxicity</b><sup>a</sup></p>
                                                            <ul>
                                                                <li>3.3% (2) patients</li>
                                                            </ul>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            : null
                                    }
                                </div>

                            </div>

                        </div>

                        : null
                }

                {
                    currentSlide == 4 && this.state.rangeMta100 == 100  ?

                        <div className={"percent-box " + (this.state.rangeMtaValue == 100 ? 'percent-box-current' : '')}>
                            <div>
                                {/* eslint-enable */}
                                <div>
                                    <p>Most frequent adverse events</p>
                                </div>
                                <div>
                                    <div className="c100 p20">
                                        <span>
                                            <span>&gt;20%</span>
                                            of patients
                                        </span>
                                        <div className="slice">
                                            <div className="bar"></div>
                                            <div className="fill"></div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <button className="btn-plus" onClick={() => {
                                        let holder = document.getElementsByClassName('ReactModalPortal');
                                        holder[0].setAttribute('style','position:fixed;top:0;left:0;right:0;bottom:0;background-color: #58647583;')

                                        this.setState({ percentBox: !this.state.percentBox })
                                    }}>+</button>
                                    {
                                        this.state.percentBox ?
                                            <div className="percent-box-copy wide">
                                                <button onClick={() => {
                                                    this.setState({ percentBox: !this.state.percentBox })
                                                    let holder = document.getElementsByClassName('ReactModalPortal');
                                                    holder[0].setAttribute('style','')

                                                }}></button>
                                                <div>
                                                    <p><strong>Adverse reactions included:</strong></p>
                                                    <div className="flex">
                                                        <div>
                                                            <ul>
                                                                <li>Increased LDH (92%)</li>
                                                                <li>Increased AST (88%)</li>
                                                                <li>Hair color changes (67%)</li>
                                                                <li>Fatigue (64%)</li>
                                                                <li>Increased ALT (64%)</li>
                                                                <li>Decreased neutrophils (44%)</li>
                                                                <li>Increased cholesterol (44%)</li>
                                                            </ul>
                                                        </div>
                                                        <div>
                                                            <ul>
                                                                <li>Increased ALP (39%)</li>
                                                                <li>Decreased lymphocytes (38%)</li>
                                                                <li>Eye edema (30%)</li>
                                                                <li>Decreased hemoglobin (30%)</li>
                                                                <li>Rash (28%)</li>
                                                                <li>Dysgeusia (26%)</li>
                                                                <li>Decreased phosphate (25%)</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            : null
                                    }
                                </div>

                            </div>

                        </div>


                        : null



                }

            </div>
        );

    }

}

export default ModelRA;
