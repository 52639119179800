import React from 'react';
import ISIContent from "./ISIContent";
import ReactModal from 'react-modal';
import "../scss/Modal.scss";

class ModalISI extends React.Component {
  
  constructor(props) {
    super(props);
      
    this.state = {
      showModalISI: false
    };
    this.handleOpenModalISI = this.handleOpenModalISI.bind(this);
    this.handleCloseModalISI = this.handleCloseModalISI.bind(this);

  }
   
  handleOpenModalISI () {
    this.setState({ showModalISI: true });
  }
  
  handleCloseModalISI () {
    this.setState({ showModalISI: false });
  }

    render() {
      
      return (
        <div className="handCursor">
          {/* <a href="#" onClick={this.handleOpenModalISI}>Important Safety Information</a> */}

          <button className="link" onClick={this.handleOpenModalISI}>Important Safety Information</button>
          <ReactModal 
            isOpen={this.state.showModalISI}
            contentLabel="onRequestClose"
            onRequestClose={this.handleCloseModalISI}
            className="Modal modal_isi"
            overlayClassName="Overlay"
            appElement={document.querySelector('.study')}
          >
            <button onClick={this.handleCloseModalISI}></button>
            <ISIContent />
          </ReactModal>      
        </div>
      );

    }

  }
  
  export default ModalISI;
  