import React from 'react';
import icon_references from '../images/icon_references.svg';
import "../scss/References.scss";

class References extends React.Component {
  constructor(){
      super();
      this.state={
          show:false
      }
  }
    render() {
       
      return (
        <div className="references">
        
          <div className="icon_references">
            <img src={icon_references} alt="" onClick={()=>{this.setState({show:!this.state.show})}} />
          </div>
          {
              this.state.show ? 
              <div className="references-copy">
                <button onClick={()=>{this.setState({show:!this.state.show})}}></button>
                <h2>References</h2>
                <ol>
                  <li>Turalio<sup>&reg;</sup> [package insert]. Basking Ridge, NJ: Daiichi Sankyo, Inc; 2021.</li>
                  <li>Ehrenstein V et al. <em>J Rheumatol</em>. 2017;44(10):1476-1483.</li>
                  <li>Mastboom MJ et al. <em>Interact J Med Res</em>. 2018;7(1)e4.</li> 
                  <li>Eisenhauer EA et al. <em>Eur J Cancer</em>. 2009;45(2):228-247.</li>
                  <li>Tap WD et al. <em>N Engl J Med</em>. 2015;373(5) (suppl):1-39.</li> 
                  <li>Healey JH et al. Presented at: Musculoskeletal Tumor Society Annual Meeting; October 2-4, 2019; Portland, Oregon.</li>
                  <li>Data on file. Daiichi Sankyo.</li>
                  <li>Data on file. Daiichi Sankyo.</li> 
                  <li>Gelderblom H. <em>Cancer</em>. 2021;127(6):884-893.</li>  
                  <li>Tap WD et al; ENLIVEN investigators. <em>Lancet</em>. 2019;394(10197):478-487.</li>
                  <li>Data on file. Daiichi Sankyo.</li>
                  <li>Data on file. Daiichi Sankyo.</li>
                  <li>Rowe PJ et al. <em>Gait Posture</em>. 2000;12:143-155.</li>
                </ol>
              </div>
        
                : null
          }
                  
        </div>
      );
    }
  }
  
  export default References;
  