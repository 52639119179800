import React from 'react';
import ModalISI from "./ModalISI";
import ModalPI from "./ModalPI";
import logo from '../images/logo.svg';
import "../scss/Header.scss";

class Header extends React.Component {
    render() {
      return (
        <header className="App-header">
            <a href="https://www.turaliohcp.com/" rel="noreferrer" target="_blank"><img src={logo} className="logo" alt="logo" /></a>
            <div className="title">Two measurements of tumor reduction provide a deeper look at treatment response in TGCT/PVNS<sup>1</sup></div>
            <div className="utility-nav">
                <ul>
                    <li><ModalISI /></li>
                    <li>
                        <ModalPI/>
                       {/* <a href="https://dsi.com/prescribing-information-portlet/getPIContent?productName=Turalio&inline=true" rel="noreferrer" target="_blank">Prescribing Information</a>*/}
                    </li>
                </ul>
            </div>
        </header>
      );
    }
  }
  
  export default Header;
  