import React from 'react';
import "../scss/ISI.scss";

class ISIContent extends React.Component {
  //   constructor(){
  //     super();
  // }
    render() {
      
      return (
        <div>

            <h2>Important Safety Information</h2>
            <div className="isi-content">
              <div className="box">
                <h3>WARNING: HEPATOTOXICITY</h3>
                <ul> 
                  <li>TURALIO<sup>&reg;</sup> can cause serious and potentially fatal liver injury.</li>
                  <li>Monitor liver tests prior to initiation of TURALIO and at speciﬁed intervals during treatment. Withhold and dose reduce or permanently discontinue TURALIO based on severity of hepatotoxicity.</li>
                  <li>TURALIO is available only through a restricted program called the TURALIO Risk Evaluation and Mitigation Strategy (REMS) Program.</li>
                </ul>
              </div>
              <h3>Indication and Usage</h3>
              <p>TURALIO<sup>&reg;</sup> (pexidartinib) is indicated for the treatment of adult patients with symptomatic tenosynovial giant cell tumor (TGCT) associated with severe morbidity or functional limitations and not amenable to improvement with surgery.</p>
              <h3>Contraindications</h3>
              <p>None.</p>
              <h3>Warnings and Precautions</h3>
              <h4>Hepatotoxicity</h4>
              <p>TURALIO can cause serious and potentially fatal liver injury and is available only through a restricted program called the TURALIO REMS. Hepatotoxicity with ductopenia and cholestasis has occurred in patients treated with TURALIO. Across 768 patients who received TURALIO in clinical trials, there were 2 irreversible cases of cholestatic liver injury. One patient with advanced cancer and ongoing liver toxicity died and one patient required a liver transplant.</p>
              <p>In ENLIVEN, 3 of 61 (5%) patients who received TURALIO developed signs of serious liver injury, deﬁned as ALT or AST ≥3 × ULN with total bilirubin ≥2 × ULN. ALT, AST and total bilirubin improved to &lt;2 × ULN in these patients 1 to 7 months after discontinuing TURALIO.</p>
              <p>The mechanism of cholestatic hepatotoxicity is unknown and its occurrence cannot be predicted. It is unknown whether liver injury occurs in the absence of increased transaminases. Please see Adverse Reactions.</p>
              <p>Avoid TURALIO in patients with preexisting increased serum transaminases, total bilirubin, or direct bilirubin (&gt;upper limit of normal [ULN]) or patients with active liver or biliary tract disease including increased alkaline phosphatase (ALP). Taking TURALIO with food increases drug exposure by 100% and may increase the risk of hepatotoxicity. Administer TURALIO on an empty stomach, either 1 hour before or 2 hours after a meal or snack. Monitor liver tests, including aspartate aminotransferase (AST), alanine aminotransferase (ALT), total bilirubin, direct bilirubin, ALP, and gamma-glutamyl transferase (GGT), prior to initiation of TURALIO, weekly for the ﬁrst 8 weeks, every 2 weeks for the next month, and every 3 months thereafter. Withhold and dose reduce, or permanently discontinue TURALIO based on the severity of the hepatotoxicity. Rechallenging with a reduced dose of TURALIO may result in a recurrence of increased serum transaminases, bilirubin, or ALP. Monitor liver tests weekly for the ﬁrst month after rechallenge.</p>
              <h4>TURALIO REMS</h4>
              <p className="mb0">TURALIO is available only through a restricted program under a REMS, because of the risk of hepatotoxicity.<br></br>Notable requirements of the TURALIO REMS Program include the following:</p>
              <ul>
                <li>Prescribers must be certiﬁed with the program by enrolling and completing training.</li>
                <li>Patients must complete and sign an enrollment form for inclusion in a patient registry.</li>
                <li>Pharmacies must be certiﬁed with the program and must dispense only to patients who are authorized (enrolled in the REMS patient registry) to receive TURALIO.</li>
              </ul>
              <p>Further information is available at <a href="https://www.turaliorems.com/#Main" target="_blank" rel="noreferrer">turalioREMS.com</a> or by calling <a href="tel:1-833-887-2546">1-833-887-2546</a>.</p>
              <h4>Embryo-fetal toxicity</h4>
              <p>Based on animal studies and its mechanism of action, TURALIO may cause fetal harm when administered to pregnant women. Advise pregnant women of the potential risk to a fetus. Advise females of reproductive potential to use an effective nonhormonal method of contraception, since TURALIO can render hormonal contraceptives ineffective, during treatment with TURALIO and for 1 month after the ﬁnal dose. Advise males with female partners of reproductive potential to use effective contraception during treatment with TURALIO and for 1 week after the ﬁnal dose.</p>
              <h3>Adverse Reactions</h3>
              <p>The safety of TURALIO was evaluated in ENLIVEN, in which patients received TURALIO without food at a dose of 400 mg in the morning and 600 mg in the evening orally for 2 weeks followed by 400 mg orally twice daily until disease progression or unacceptable toxicity.</p>
              <p>Serious adverse reactions were reported in 13% of patients who received TURALIO. The most frequent serious adverse reactions (occurring in &gt;1 patient) included abnormal liver tests (3.3%) and hepatotoxicity (3.3%).</p>
              <p>Permanent discontinuation due to adverse reactions occurred in 13% of patients who received TURALIO. The most frequent adverse reactions (occurring in &gt;1 patient) requiring permanent discontinuation included increased ALT (4.9%), increased AST (4.9%), and hepatotoxicity (3.3%).</p>
              <p>Dose reductions or interruptions occurred in 38% of patients who received TURALIO. The most frequent adverse reactions (occurring in &gt;1 patient) requiring a dosage reduction or interruption were increased ALT (13%), increased AST (13%), nausea (8%), increased ALP (7%), vomiting (4.9%), increased bilirubin (3.3%), increased GGT (3.3%), dizziness (3.3%), and abdominal pain (3.3%).</p>
              <p>The most common adverse reactions for all grades (&gt;20%) were increased lactate dehydrogenase (92%), increased AST (88%), hair color changes (67%), fatigue (64%), increased ALT (64%), decreased neutrophils (44%), increased cholesterol (44%), increased ALP (39%), decreased lymphocytes (38%), eye edema (30%), decreased hemoglobin (30%), rash (28%), dysgeusia (26%), and decreased phosphate (25%).</p>
              <p>Clinically relevant adverse reactions occurring in &lt;10% of patients were blurred vision, photophobia, diplopia, reduced visual acuity, dry mouth, stomatitis, mouth ulceration, pyrexia, cholangitis, hepatotoxicity, liver disorder, cognitive disorders (memory impairment, amnesia, confusional state, disturbance in attention, and attention deﬁcit/hyperactivity disorder), alopecia, and skin pigment changes (hypopigmentation, depigmentation, discoloration, and hyperpigmentation).</p>
              <h3>Drug Interactions</h3>
              <ul>
                <li><u>Use with hepatotoxic products</u>: TURALIO can cause hepatotoxicity. In patients with increased serum transaminases, total bilirubin, or direct bilirubin (&gt;ULN) or active liver or biliary tract disease, avoid coadministration of TURALIO with other products known to cause hepatotoxicity.</li>
                <li><u>Moderate or strong CYP3A inhibitors</u>: Concomitant use of a moderate or strong CYP3A inhibitor may increase pexidartinib concentrations. Reduce TURALIO dosage if concomitant use of moderate or strong CYP3A inhibitors cannot be avoided.</li>
                <li><u>Strong CYP3A inducers</u>: Concomitant use of a strong CYP3A inducer decreases pexidartinib concentrations. Avoid concomitant use of strong CYP3A inducers.</li>
                <li><u>Uridine diphosphate glucuronosyltransferase (UGT) inhibitors</u>: Concomitant use of a UGT inhibitor increases pexidartinib concentrations. Reduce TURALIO dosage if concomitant use of UGT inhibitors cannot be avoided.</li>
                <li><u>Acid-reducing agents</u>: Concomitant use of a proton pump inhibitor (PPI) decreases pexidartinib concentrations. Avoid concomitant use of PPIs. Use histamine-2 receptor antagonists or antacids if needed.</li>
                <li><u>CYP3A substrates</u>: TURALIO is a moderate CYP3A inducer. Concomitant use of TURALIO decreases concentrations of CYP3A substrates. Avoid coadministration of TURALIO with hormonal contraceptives and other CYP3A substrates where minimal concentration changes may lead to serious therapeutic failure. Increase the CYP3A substrate dosage in accordance with approved product labeling if concomitant use is unavoidable.</li>
              </ul>
              <h3>Use in Speciﬁc Populations</h3>
              <ul>
                <li><strong>Pregnancy:</strong> TURALIO may cause embryo-fetal harm when administered to pregnant women. Advise pregnant women of the potential risk to a fetus.</li>
                <li><strong>Lactation:</strong> Because of the potential for serious adverse reactions in the breastfed child, advise women to not breastfeed during treatment with TURALIO and for at least 1 week after the ﬁnal dose.</li>
                <li><strong>Females and males of reproductive potential:</strong> Verify pregnancy status in females of reproductive potential prior to the initiation of TURALIO. Advise females of reproductive potential to use an effective nonhormonal method of contraception, since TURALIO can render hormonal contraceptives ineffective, during treatment with TURALIO and for 1 month after the ﬁnal dose. Advise male patients with female partners of reproductive potential to use effective contraception during treatment with TURALIO and for 1 week after the ﬁnal dose.</li>
                <li><strong>Renal impairment:</strong> Reduce the dose when administering TURALIO to patients with mild to severe renal impairment (CLcr 15 to 89 mL/min, estimated by Cockcroft-Gault [C-G] using actual body weight).</li>
                <li><strong>Hepatic impairment:</strong> Reduce the dosage of TURALIO for patients with moderate hepatic impairment (total bilirubin greater than 1.5 and up to 3 times ULN, not due to Gilbert’s syndrome, with any AST). TURALIO has not been studied in patients with severe hepatic impairment (total bilirubin greater than 3 to 10 times ULN and any AST).</li>
              </ul>
              <p className="bold mt10">To report SUSPECTED ADVERSE REACTIONS, contact Daiichi Sankyo, Inc, at 1-877-437-7763 or FDA at 1-800-FDA-1088 or <a href="https://www.fda.gov/safety/medwatch-fda-safety-information-and-adverse-event-reporting-program" target="_blank" rel="noreferrer">fda.gov/medwatch</a>.</p>
             
            </div>

        </div>
      );
    }
  }
  
  export default ISIContent;
  