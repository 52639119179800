import React from 'react';
import "../scss/Footer.scss";

class Footer extends React.Component {
    render() {
      return (
        <div className="footer">
            <p>TURALIO<sup>&reg;</sup> is a trademark of Daiichi Sankyo Company, Limited. Access Central&trade; is a trademark of Daiichi Sankyo, Inc.<br></br>&copy; 2022 Daiichi Sankyo, Inc. PP-US-TU-0879 02/22</p>
        </div>
      );
    }
  }
  
  export default Footer;
  