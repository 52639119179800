import React from 'react';
import AppContext from './AppContext';
import Slider from "react-slick";
import References from "./References";
import ModalEnlivenData from "./ModalEnlivenData";
import ModalPooledData from "./ModalPooledData";
import ModalSecondaryEndpoint from "./ModalSecondaryEndpoint";
import ModalROMData from "./ModalROMData";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../scss/Study.scss";

class Study extends React.Component {

  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.intro = this.intro.bind(this);
    this.enliven = this.enliven.bind(this);
    this.previous = this.previous.bind(this);
    // this.state = {currentSlide: 0};
    this.changeSlide = this.changeSlide.bind(this);
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
  // jump to intro section
  intro() {
    this.slider.slickGoTo(0);
  }
  // jump to enliven section
  enliven() {
    this.slider.slickGoTo(2);
  }

  changeSlide(current) {
    // Get the global variables & functions via context
    const { setCurrentSlide } = this.context;
    setCurrentSlide(current);
  }

  render() {

    const { currentSlide } = this.context;

    const settings = {
      dots: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      afterChange: current => this.changeSlide(current)
    };

    return (
      <div className="study">
        <References />
        <div className={"study-header " + (currentSlide === 0 || currentSlide === 1 ? '' : 'study-current')}>
          <div onClick={this.intro}>Introduction</div>
          <div onClick={this.enliven}>ENLIVEN Study</div>
        </div>
        <Slider ref={c => (this.slider = c)} {...settings}>
          <div key={1}>
            <div className={"inner-div"}>
              <h2>Introduction</h2>
              <p><strong>Tenosynovial giant cell tumor (TGCT)</strong>, also known as pigmented villonodular synovitis (PVNS) or giant cell tumor of the tendon sheath (GCT-TS), can often present as an irregularly shaped tumor. In such cases, it may be useful to supplement traditional, linear measurements of tumor response based on the RECIST v1.1 criteria with a multidimensional measurement. By evaluating TVS, a more complete characterization of tumor response and treatment efficacy can be obtained.<sup>2-5</sup></p>
              <p className="reference">RECIST, Response Evaluation Criteria In Solid Tumors; TVS, tumor volume score.</p>
              <h2 className="mb10">Select Important Safety Information</h2>
              <div className="isi-content">
                <div className="box">
                  <h3>WARNING: HEPATOTOXICITY</h3>
                  <ul>
                    <li>TURALIO can cause serious and potentially fatal liver injury.</li>
                    <li>Monitor liver tests prior to initiation of TURALIO and at speciﬁed intervals during treatment. Withhold and dose reduce or permanently discontinue TURALIO based on severity of hepatotoxicity.</li>
                    <li>TURALIO is available only through a restricted program called the TURALIO Risk Evaluation and Mitigation Strategy (REMS) Program.</li>
                  </ul>
                </div>
              </div>

            </div>
          </div>
          <div key={2}>
            <div className={"inner-div"}>
              <h2>Introduction</h2>
              <p className="subtitle">Response Evaluation Criteria In Solid Tumors (RECIST) v1.1</p>
              <p>Overall response rate (ORR) based on RECIST is an efficacy endpoint that calculates tumor length across the single longest diameter in the plane of measurement.<sup>4</sup></p>
              <p className="subtitle">Tumor volume score (TVS):</p>
              <p>ORR based on TVS is a TGCT-specific efficacy endpoint that calculates estimated volume of the maximally distended synovial cavity or tendon sheath involved, measured in 10% increments.<sup>5</sup></p>
              <p>TVS is a multidimensional approach to measuring tumor-burden reduction.<sup>5</sup></p>
              <p>TVS was developed for the phase 3, double-blind, placebo-controlled ENLIVEN study that evaluated TURALIO (pexidartinib) in patients with advanced, symptomatic TGCT.<sup>5</sup></p>
              <h2 className="mb10">Select Important Safety Information</h2>
              <p className="subtitle">Indication and Usage</p>
              <p>TURALIO<sup>&reg;</sup> (pexidartinib) is indicated for the treatment of adult patients with symptomatic tenosynovial giant cell tumor (TGCT) associated with severe morbidity or functional limitations and not amenable to improvement with surgery.</p>
              <p className="subtitle">Contraindications</p>
              <p>None.</p>
            </div>
          </div>
          <div key={3}>
            <div className={"inner-div"}>
              <h2>ENLIVEN Study Design</h2>
              <p>ENLIVEN was a phase 3, randomized, placebo-controlled, clinical trial of 120 patients with TGCT for whom surgery was not recommended. ENLIVEN evaluated patients with and without prior surgery. Patients were randomized to placebo (n=59) or TURALIO (n=61) 1000 mg daily for 2 weeks followed by 400 mg twice daily. Treatment continued until unacceptable toxicity or disease progression. The FDA-approved dose is 400 mg orally twice daily.<sup>1</sup></p>
              <p><strong>The primary efficacy endpoint</strong> measured at week 25 was overall response rate (ORR) per RECIST v1.1.<sup>1</sup></p>
              <p><strong>Secondary endpoints</strong> included ORR by TVS and mean change from baseline in range of motion (ROM) of the affected joint.<sup>1</sup></p>
              <p className="reference mb20">RECIST, Response Evaluation Criteria In Solid Tumors; TVS, tumor volume score.</p>
              <h2 className="mb5">Select Important Safety Information</h2>
              <p className="subtitle">Warnings and Precautions</p>
              <p className="bold mb5">Hepatotoxicity</p>
              <p>TURALIO can cause serious and potentially fatal liver injury and is available only through a restricted program called the TURALIO REMS. Hepatotoxicity with ductopenia and cholestasis has occurred in patients treated with TURALIO. Across 768 patients who received TURALIO in clinical trials, there were 2 irreversible cases of cholestatic liver injury. One patient with advanced cancer and ongoing liver toxicity died and one patient required a liver transplant.</p>

            </div>
          </div>
          <div key={4}>
            <div className={"inner-div"}>
              <p className="subtitle">Primary endpoint: overall response rate</p>
              <h2>38% of TURALIO patients demonstrated a reduction in tumor length of 30% or more at 25 weeks<sup>1,4</sup></h2>
              <p>Of patients receiving TURALIO, 23% (n=14) had a partial response (PR) and 15% (n=9) had a complete response (CR) at week 25<sup>1</sup></p>
              <div className="box-green"><p>100% of patients experienced at least some reduction in the length of their tumor<sup>6</sup></p></div>
              <p className="reference">ORR was determined by blinded independent central review (BICR).<sup>1</sup><br></br>PR was deﬁned as 30% reduction in tumor length.<sup>4</sup><br></br>CR was defined as disappearance of all target lesions.<sup>4</sup></p>
              <p>At ~3 years of follow-up, 62% of TURALIO patients demonstrated a reduction in tumor length<sup>7,a</sup></p>
              <p className="reference"><sup>a</sup>An exploratory analysis measured the best overall response achieved with TURALIO from the start of treatment until a median of 38 months (data cutoff of May 31, 2019). The analysis of this endpoint was not included in the prespecified statistical analysis plan to control for type 1 error; as such, it may be susceptible to bias.<sup>7,8</sup></p>
              <div className="buttons-wrap">
                <ModalEnlivenData />
                <ModalPooledData />
              </div>
              <h2 className="mb5">Select Important Safety Information</h2>
              <p>In ENLIVEN, 3 of 61 (5%) patients who received TURALIO developed signs of serious liver injury, defined as ALT or AST ≥3 × ULN with total bilirubin ≥2 × ULN. ALT, AST and total bilirubin improved to &lt;2 × ULN in these patients 1 to 7 months after discontinuing TURALIO.</p>
              <p>The mechanism of cholestatic hepatotoxicity is unknown and its occurrence cannot be predicted. It is unknown whether liver injury occurs in the absence of increased transaminases. Please see Adverse Reactions.</p>

            </div>
          </div>
          <div key={5}>
            <div className={"inner-div"}>
              <p className="subtitle">Secondary endpoint: overall response rate</p>
              <h2>Most TURALIO patients showed reduction in tumor volume of 50% or more—at 25 weeks and over 3-year follow-up<sup>1,5,7</sup></h2>
              <p>56% of patients achieved ORR (n=34 of 61;  95% CI, 43%-67%).<sup>1</sup><br></br>Of patients receiving TURALIO, 51% (n=31) had a PR and 5% (n=3) had a CR at week 25.<sup>10</sup></p>
              <p className="reference">PR was deﬁned as 50% reduction in tumor volume by TVS.<sup>5</sup> </p>
              <p>With a longer follow-up of 38 months, 66% of patients had at least 50% reduction in tumor volume<sup>5,7,a</sup></p>
              <p className="reference"><sup>a</sup>An exploratory analysis measured the best overall response achieved with TURALIO from the start of treatment until a median of 38 months (data cutoff of May 31, 2019). The analysis of this endpoint was not included in the prespecified statistical analysis plan to control for type 1 error; as such, it may be susceptible to bias.<sup>7,8</sup></p>
              <ModalSecondaryEndpoint />
              <h2 className="mb10">Select Important Safety Information</h2>
              <p className="subtitle">Adverse Reactions</p>
              <p>Permanent discontinuation due to adverse reactions occurred in 13% of patients who received TURALIO. The most frequent adverse reactions (occurring in &gt;1 patient) requiring permanent discontinuation included increased ALT (4.9%), increased AST (4.9%), and hepatotoxicity (3.3%).</p>
            </div>
          </div>
          <div key={6}>
            <div className={"inner-div"}>
            <p className="subtitle">Secondary endpoint: change in range of motion</p>
            <h2 className="mb10">2X greater relative improvement in range of motion (ROM) for people taking TURALIO at 25 weeks vs placebo<sup>1,10</sup></h2>
            <p>In ENLIVEN, range of motion was defined as mean percentage change from baseline relative to a reference standard for the same joint.<sup>7</sup> On average, 6.7% improvement in ROM for the knee correlates with a 10-degree improvement in joint movement.<sup>10,11</sup></p>
            <ul>
              <li>Mean ROM in patients taking TURALIO at the start of the study was 62.5%; ROM had improved 15.1% from baseline to about 77.6% of possible movement for the joint at 25 weeks<sup>10</sup></li>
              <li>Patients taking placebo had a 62.9% mean ROM at the start of the study and experienced an improvement of 6.2% in ROM to a total of 69.1%<sup>10</sup></li>
              <li>Joints represented in the analysis (TURALIO vs placebo, respectively) included the knee (n=25; n=28), ankle (n=11; n=7), and other (n=9; n=8)<sup>12</sup></li>
            </ul>
            <ModalROMData />
            <p className="reference">The total population studied was 120 patients. Results were excluded for 1 patient with missing baseline and 31 patients with a missing ROM assessment at week 25. Assessments were performed by a third-party clinical assessor using a goniometer.<sup>1</sup></p>
            <h2 className="mb0">Select Important Safety Information</h2>
            <p className="subtitle mb0">Adverse Reactions</p>
            <p>Dose reductions or interruptions occurred in 38% of patients who received TURALIO. The most frequent adverse reactions (occurring in &gt;1 patient) requiring a dosage reduction or interruption were increased ALT (13%), increased AST (13%), nausea (8%), increased ALP (7%), vomiting (4.9%), increased bilirubin (3.3%), increased GGT (3.3%), dizziness (3.3%), and abdominal pain (3.3%).</p>
            </div>
          </div>
        </Slider>
        <div className="sidebar-ftr">

          {
            currentSlide !== 0 ?
              <button className="button" onClick={this.previous}>&lt; Back</button>
              : <div></div>

          }
          <button className="button" onClick={this.next}>Next &gt;</button>

        </div>

      </div>
    );
  }
}

export default Study;
