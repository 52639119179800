import React, { useState } from 'react';
import AppContext from "./components/AppContext";
import Header from "./components/Header";
import Main from "./components/Main";
import Footer from "./components/Footer";
import Study from "./components/Study";
// import ISI from "./components/ISI";

function App() {
  
  const [currentSlide, setCurrentSlide] = useState(0);
  
  return (
    <AppContext.Provider value={{
      currentSlide,
      setCurrentSlide
    }}>
      <div className="App">
        <Header />

        <div className="main-wrapper">
          <div className="main">
            <Main  />
            <Footer />
          </div>
          <div className="sidebar">
            <Study />
          </div>
        </div>

      </div>
    </AppContext.Provider>
  );
}

export default App;
