import React from 'react';
import ReactModal from 'react-modal';
import "../scss/Modal.scss";

class ModalEnlivenData extends React.Component {
  
  constructor(props) {
    super(props);
      
    this.state = {
      showModalEnlivenData: false
    };
    this.handleOpenModalEnlivenData = this.handleOpenModalEnlivenData.bind(this);
    this.handleCloseModalEnlivenData = this.handleCloseModalEnlivenData.bind(this);

  }
   
  handleOpenModalEnlivenData () {
    this.setState({ showModalEnlivenData: true });
  }
  
  handleCloseModalEnlivenData () {
    this.setState({ showModalEnlivenData: false });
  }

    render() {
      
      return (
        <div>
          <button className="button button-data" onClick={this.handleOpenModalEnlivenData}>See ENLIVEN data</button>
          <ReactModal 
            isOpen={this.state.showModalEnlivenData}
            contentLabel="onRequestClose"
            onRequestClose={this.handleCloseModalEnlivenData}
            className="Modal modal_enliven_data"
            overlayClassName="Overlay"
            appElement={document.querySelector('.study')}
          >
            <button onClick={this.handleCloseModalEnlivenData}></button>
            <div>
              <img src="/images/enliven_data.png" alt="" />
            </div>
          </ReactModal>      
        </div>
      );
    }
  }
  
  export default ModalEnlivenData;
  