import React from 'react';
import ReactModal from 'react-modal';
import "../scss/Modal.scss";

class ModalPI extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showModalPI: false
        };
        this.handleOpenModalPI = this.handleOpenModalPI.bind(this);
        this.handleCloseModalPI = this.handleCloseModalPI.bind(this);

    }

    handleOpenModalPI () {
        this.setState({ showModalPI: true });
    }

    handleCloseModalPI () {
        this.setState({ showModalPI: false });
    }

    render() {

        return (
            <div className="handCursor">
                {/* <a href="#" onClick={this.handleOpenModalISI}>Important Safety Information</a> */}

                <button className="link" onClick={this.handleOpenModalPI}>Prescribing Information</button>
                <ReactModal
                    isOpen={this.state.showModalPI}
                    contentLabel="onRequestClose"
                    onRequestClose={this.handleCloseModalPI}
                    className="Modal modal_isi"
                    overlayClassName="Overlay"
                    appElement={document.querySelector('.study')}
                >
                    <button onClick={this.handleCloseModalPI}></button>
                    <div className="iframe-wrapper">
                        <iframe title="PI" src="https://dsi.com/prescribing-information-portlet/getPIContent?productName=Turalio&inline=true" width="100%" height="100%" style={{border:'none'}}/>
                    </div>
                </ReactModal>
            </div>
        );

    }

}

export default ModalPI;
