import React from 'react';
import AppContext from './AppContext';
import { Range, getTrackBackground } from "react-range";
import { Clock } from 'three';
import { Scene } from 'three';
import { TOUCH } from 'three';
import { PerspectiveCamera } from 'three';
import { AmbientLight } from 'three';
import { PointLight } from 'three';
import { WebGLRenderer } from 'three';
import { ACESFilmicToneMapping } from 'three';
import { sRGBEncoding } from 'three';
import { Box3 } from 'three';
import { Vector3 } from 'three';
import { AnimationMixer } from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import "../scss/ModelROM.scss";

class ModelROM extends React.Component {

    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            rangeROMValue: [0],
            currentMorphValue: 1.6
        };
        this.scaleROM0 = this.scaleROM0.bind(this);
        this.scaleROM100 = this.scaleROM100.bind(this);
        this.changeModel = this.changeModel.bind(this);
    }

    componentDidMount = () => {

        let scene, camera, renderer;
        let modelElSizeX = 1040,
            modelEleSizeY = 676;

        let clock,
            modelROM,
            mixer = null;

        function init() {
            var controls, hlight,  light; //directionalLight,
            clock = new Clock();
            scene = new Scene();
            // scene.background = new Color(0x000000);

            camera = new PerspectiveCamera(
                35,
                modelElSizeX / modelEleSizeY,
                0.05,
                300
            );
            camera.position.z = -2

            hlight = new AmbientLight(0xffffff, 10);
            scene.add(hlight);

            light = new PointLight(0xfffffff, 10);
            light.position.set(0, 300, 500);
            scene.add(light);

            renderer = new WebGLRenderer({ antialias: true, alpha:true });
            renderer.setSize(modelElSizeX, modelEleSizeY);
            renderer.setPixelRatio(window.devicePixelRatio);
            renderer.toneMapping = ACESFilmicToneMapping;
            renderer.toneMappingExposure = 1;
            renderer.outputEncoding = sRGBEncoding;
            renderer.setClearColor( 0xffffff, 0);

            document.getElementsByClassName('modelROM')[0].appendChild(renderer.domElement);

            controls = new OrbitControls(camera, renderer.domElement);

            controls.touches = {
                ONE: TOUCH.ROTATE,
                TWO: TOUCH.DOLLY_PAN
            }

            controls.minDistance = 1.25;
            controls.maxDistance = 5;
            controls.target.set(0, 0, -0.2);
            controls.update();

            var loader = new GLTFLoader();
            loader.load(
                "model/KneeBend/KneeBend_Goniometer.gltf",
                function (gltf) {
                    modelROM = gltf.scene;
                    if(!window.theModelROM) window.theModelROM = modelROM;

                    var box = new Box3().setFromObject(modelROM);
                    var center = new Vector3();
                    box.getCenter(center);
                    modelROM.position.sub(center); // center the modelROM
                    scene.rotation.y = -100 * Math.PI/180

                    scene.add(modelROM);
                    console.log('knee model',modelROM.children);

                    // Set initial knee position to 1.5 value


                    console.log(modelROM);
                    if (gltf.animations[0]) {
                        mixer = new AnimationMixer(modelROM);
                    }

                    animate();
                    // toggleAnimate();
                }, // called while loading is progressing
                function (xhr) {
                    console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
                },
                // called when loading has errors
                function (error) {
                    console.log("An error happened", error);
                }
            );
        }

        function animate() {
            requestAnimationFrame(animate);

            var delta = clock.getDelta();
            if (mixer !== null) {
                mixer.update(delta);
            }

            // renderer.gammaOutput = false; // to fix washed-out model
            renderer.toneMappingExposure = 0.1;  // to fix washed-out model
            renderer.render(scene, camera);
        }

        init();

    }

    scaleROM100 = () => {
        let count=0;
        // eslint-disable-next-line
        this.state.currentMorphValue = 0;
        // eslint-disable-next-line
        let currentMorphValue = this.state.currentMorphValue;

        let timeInt = window.setInterval(function(){
            window.theModelROM.children[8].morphTargetInfluences[0]  = currentMorphValue;

            currentMorphValue = currentMorphValue + 0.2;
            count++;
            if(count >= 8) {
                window.theModelROM.children[8].morphTargetInfluences[0]  = 1.6;
                clearInterval(timeInt);
            }
        },100);
    }

    scaleROM0 = () => {
        // eslint-disable-next-line
        let count=0;
        // eslint-disable-next-line
        this.state.currentMorphValue = 1.6;

        let currentMorphValue = this.state.currentMorphValue;

        let timeInt = window.setInterval(function(){
            window.theModelROM.children[8].morphTargetInfluences[0]  = currentMorphValue;

            currentMorphValue = currentMorphValue - 0.2;
            count++;
            if(count >= 8) {
                window.theModelROM.children[8].morphTargetInfluences[0]  = 0;
                clearInterval(timeInt);
            }
        },100);

    }

    changeModel = (rangeROMValue) => {
        // eslint-disable-next-line
        if(rangeROMValue[0] == 0) {
            this.scaleROM0();
            // eslint-disable-next-line
        } else if(rangeROMValue[0] == 100) {
           this.scaleROM100();
        }

    }

    render() {

    let step = 100,
    min = 0,
    max = 100,
    green = '#B2C43F',
    sky = '#A5C8E4';

    // function changeModel(params) {
    //     //scaleTumor();
    //     //x: -0.2650567
    //    // y: -1.12979686
    //    // z: -0.7856848
    // }

    const {currentSlide} = this.context;

    return (
        <div ref={el => (this.container = el)}>
            {/* eslint-disable */}
            <div className={"range-slider range-slider-rom " + (currentSlide == 5 ? 'range-current' : '')}>
                <h3>ROM</h3>
                <div>
                <Range
                    step={step}
                    min={min}
                    max={max}
                    values={this.state.rangeROMValue}
                    onChange={(rangeROMValue) => this.setState({ rangeROMValue })}
                    onFinalChange={(rangeROMValue) => this.changeModel(rangeROMValue)}
                    renderMark={({ props, index }) => (
                        <div
                          {...props}
                          className="slider-point"
                        >
                            <div className="point-label">
                            </div>

                        </div>

                    )}
                    renderTrack={({ props, children }) => (
                        <div
                            {...props}
                            className="slider-bar"
                            style={{
                                background: getTrackBackground({
                                    values: this.state.rangeROMValue,
                                    colors: [sky,green],
                                    min: min,
                                    max: max
                                })
                            }}
                        >
                            {children}
                        </div>
                    )}
                    renderThumb={({ props }) => (
                    <div
                        {...props}
                        className="slider-pointer"
                    />
                    )}
                />
                </div>
            </div>

        {
              currentSlide == 5 ?

                <div className={"rangeROM0 " + (this.state.rangeROMValue == 0 ? 'range-box-current' : '')}>
                    <div>
                        <p>ROM at baseline</p>
                    </div>
                </div>

                : null
        }

        {
              currentSlide == 5 ?

                <div className={"rangeROM100 " + (this.state.rangeROMValue == 100 ? 'range-box-current' : '')}>
                    {/* eslint-enable */}
                    <div>
                        <div><p>2X greater improvement in ROM<br></br>on TURALIO at 25 weeks: 15.1% <br></br>vs 6.2% for placebo<sup>10</sup></p></div>
                        <div><p>Patients on TURALIO achieved a mean<br></br>of 77.6% of total possible movement,<br></br>with ROM improvement of 15.1%<br></br>from baseline<sup>10</sup></p></div>
                        <div className="target-point"><p>ROM at 25 weeks</p></div>
                        <div>77.6%</div>
                    </div>
                </div>

                : null
        }




        </div>
      );

    }

  }

  export default ModelROM;
