import React from 'react';
import ReactModal from 'react-modal';
import "../scss/Modal.scss";

class ModalPooledData extends React.Component {
  
  constructor(props) {
    super(props);
      
    this.state = {
      showModalPooledData: false
    };
    this.handleOpenModalPooledData = this.handleOpenModalPooledData.bind(this);
    this.handleCloseModalPooledData = this.handleCloseModalPooledData.bind(this);

  }
   
  handleOpenModalPooledData () {
    this.setState({ showModalPooledData: true });
  }
  
  handleCloseModalPooledData () {
    this.setState({ showModalPooledData: false });
  }

    render() {
      
      return (
        <div>
          <button className="button button-data" onClick={this.handleOpenModalPooledData}>See long-term<br />pooled data</button>
          <ReactModal 
            isOpen={this.state.showModalPooledData}
            contentLabel="onRequestClose"
            onRequestClose={this.handleCloseModalPooledData}
            className="Modal modal_pooled_data"
            overlayClassName="Overlay"
            appElement={document.querySelector('.study')}
          >
            <button onClick={this.handleCloseModalPooledData}></button>
            <div>
              <div className="text-div1">
                <h2>Additional long-term, pooled follow-up analysis from 3 TURALIO-treated TGCT <br />patient cohorts—May 31, 2019 data cutoff<sup>9</sup></h2>
                <h4>Pooled long-term analysis of 130 patients on TURALIO from 3 cohorts<sup>9</sup></h4>
                <ul>
                  <li>TGCT patient cohort from the phase 1 study of pexidartinib in solid tumors (n=39)<sup>a</sup></li>
                  <li>Phase 3 ENLIVEN patients randomized to TURALIO (n=61)</li>
                  <li>Phase 3 ENLIVEN crossover patients (n=30)</li>
                </ul>
                <h4>Post-hoc pooled analysis showed a durable long-term response with TURALIO<sup>9</sup></h4>
                <ul>
                  <li>ORR=60% (n=78/130, 95% CI: 51.4%-68.0%) defined by RECIST as CR or PR with a median follow-up of 39 months (range: 32-82 months)</li>
                  <li>The median time to an initial response was 3.4 months as assessed by RECIST v1.1</li>
                  <li>According to RECIST, of the 130 total patients, 16 (12%) progressed on treatment or after treatment discontinuation, 14 (11%) progressed <br />on treatment, and 2 (2%) progressed after treatment</li>
                </ul>
              </div>
              <div className="two-cols flex">
                <div className="left-col">
                  <div className="green-div">
                    <p>In the pooled analysis 60% of patients achieved ORR, of these, <br />most achieved results by 6 months on TURALIO<sup>9</sup></p>
                  </div>
                  <div className="chart-and-footnote">
                    <img src="/images/pooled_data_modal.svg" alt="" />
                    <p>CR, complete response; ITT, intent-to-treat; ORR, overall response rate; PR, partial response; RECIST, response evaluation <br />criteria in solid tumors; TEAE, treatment-emergent adverse event.</p>
                    <p>ORR defined as CR+PR per RECIST v1.1 in the ITT population.<sup>9</sup></p>
                    <div className="flex">
                      <div>
                        <p><sup>a</sup></p>
                      </div>
                      <div>
                        <p>The phase 1 study was the first in-human study with a dose-escalation phase with an expansion cohort phase (39 patients <br />with TGCT) conducted in patients with solid tumors. Pexidartinib at 1000 mg/d (split in twice daily dosing) was taken until <br />tumor progression or unacceptable toxicities.<sup>9</sup></p>
                      </div>
                    </div>
                    <div className="flex">
                      <div>
                        <p><sup>b</sup></p>
                      </div>
                      <div>
                        <p>The pooled population had a median duration of treatment of 19 months, with treatment ongoing in 54 patients (42%) as of <br />the May 31, 2019 data cutoff.<sup>9</sup></p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="right-col">
                  <div className="blue-div">
                    <p>
                      TURALIO data from the <br />pooled post-hoc analysis <br />demonstrated that the safety <br />profile is consistent with data <br />from the ENLIVEN study with <br />no late-emerging toxicities<sup>9</sup>
                    </p>
                    <ul>
                      <li>
                        In the pooled analysis of 130 patients, the <br />most common adverse reactions were <br />similar to those seen in the original data <br />analysis, cutoff of March 2017
                      </li>
                      <li>
                        TURALIO was generally well tolerated, <br />with most TEAEs being low Grade (1 or 2) <br />even with long-term treatment, the most <br />frequent adverse events were hair color <br />change (75%), followed by fatigue (61%), <br />nausea (47%), arthralgia (39%), diarrhea <br />(32%), aspartate aminotransferase increase <br />(31%), and dysgeusia (30%)
                      </li>
                      <li>
                        No new safety signals or serious mixed or <br />cholestatic hepatotoxicity cases occurred <br />during long-term follow-up
                      </li>
                      <li>
                        Most liver abnormalities (92%) were <br />aminotransferase elevations; 4 patients <br />3%) experienced mixed/cholestatic <br />hepatotoxicity (all within the first 2 months <br />of treatment), which was reversible in all <br />cases (recovery spanned 1 to 7 months)
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </ReactModal>      
        </div>
      );
    }
  }
  
  export default ModalPooledData;
  