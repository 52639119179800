import React from 'react';
import ReactModal from 'react-modal';
import "../scss/Modal.scss";

class ModalSecondaryEndpoint extends React.Component {
  
  constructor(props) {
    super(props);
      
    this.state = {
      showModalSecondaryEndpoint: false
    };
    this.handleOpenModalSecondaryEndpoint = this.handleOpenModalSecondaryEndpoint.bind(this);
    this.handleCloseModalSecondaryEndpoint = this.handleCloseModalSecondaryEndpoint.bind(this);

  }
   
  handleOpenModalSecondaryEndpoint () {
    this.setState({ showModalSecondaryEndpoint: true });
  }
  
  handleCloseModalSecondaryEndpoint () {
    this.setState({ showModalSecondaryEndpoint: false });
  }

    render() {
      
      return (
        <div>
          <button className="button button-data mb20" onClick={this.handleOpenModalSecondaryEndpoint}>See data</button>
          <ReactModal 
            isOpen={this.state.showModalSecondaryEndpoint}
            contentLabel="onRequestClose"
            onRequestClose={this.handleCloseModalSecondaryEndpoint}
            className="Modal modal_secondary_endpoint"
            overlayClassName="Overlay"
            appElement={document.querySelector('.study')}
          >
            <button onClick={this.handleCloseModalSecondaryEndpoint}></button>
            <div>
              <img src="/images/secondary_endpoint.png" alt="" />
              <p>BICR, blinded independent central review; CI, confidence interval; ITT, intent-to-treat; ORR, overall response rate;<br></br>TVS, tumor volume score.</p>
              <p><sup>a</sup>ORR was determined by BICR.<sup>1</sup></p>
              <p><sup>b</sup>Fisher’s exact test.<sup>10</sup></p>
              <p><sup>c</sup>An exploratory analysis measured the best overall response achieved with TURALIO from the start of treatment until a median of 38 months (data cutoff May 31, 2019). The analysis of this endpoint was not included in the prespecified statistical analysis plan to control for type 1 error; as such, it may be susceptible to bias.<sup>7,8</sup></p>
            </div>
          </ReactModal>
        </div>
      );
    }
  }
  
  export default ModalSecondaryEndpoint;
  